import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Amplify, { API } from "aws-amplify";
import awsExports from "./aws-exports";
import "bootstrap/dist/css/bootstrap.min.css";
Amplify.configure(awsExports);

ReactDOM.render(<App />, document.getElementById("root"));
