import React, { Component } from "react";
import { ReservationApp } from "./ReservationApp";
import { AdLogin } from "./AdLogin";

class SamlAuthWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    };
    this.updateUsername = this.updateUsername.bind(this);
  }

  updateUsername(newUser) {
    this.setState({ user: newUser });
    console.log(this.state.user);
  }

  render() {
    return (
      <div>
        <AdLogin
          authState={this.props.authState}
          updateUsername={this.updateUsername}
          onStateChange={this.props.onStateChange}
        />
        <ReservationApp
          userInfo={this.state.user}
          authState={this.props.authState}
          updateUsername={this.updateUsername}
          onStateChange={this.props.onStateChange}
        />
      </div>
    );
  }
}

export default SamlAuthWrapper;
