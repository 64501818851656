import React from "react";
import "./App.css";
import AuthWrapper from "./components/Auth/SamlAuthWrapper";
import awsconfig from "./aws-exports";
import { Authenticator } from "aws-amplify-react";

function App() {
  return (
    <div>
      <Authenticator
        hideDefault={true}
        amplifyConfig={awsconfig}
        usernameAttributes="email"
      >
        <AuthWrapper />
      </Authenticator>
    </div>
  );
}

export default App;
