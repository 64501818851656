import React, { Component } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import awsmobile from "../../aws-exports"; // Amplify configuration
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import "./Login.css";

// ===================================================
// setup Amplify and its Auth model

Amplify.configure(awsmobile);

Auth.configure({ awsmobile }); // https://aws-amplify.github.io/amplify-js/api/classes/authclass.html

// ===================================================
// set up logging properly so we can use logger.debug logger.error logger.info etc
const logger = new Logger("AppLog", "INFO");

// ===================================================
// Main class for our Application that gets injected into the root in index.html via index.js
export class AdLogin extends Component {
  // setup props
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  // define vars in state (could be done in props constructor)
  state = {
    authState: "signIn", // used to check login in render()
    token: null, // used to check if we've got a valid login
    user: null, // used to store the user object returned from currentAuthenticatedUser()
  };

  // ====================================================
  // getuserinfo(): custom function to get tokencode
  // getuserinfo function to retrieve via amplify & cognito the currentAuthenticatedUser
  getuserinfo = async () => {
    // call a promise to waith for currentAuthenticatedUser() to return
    const user = await Auth.currentAuthenticatedUser();
    console.log("user ==" + user);
    // do a debug log entry
    logger.debug(user);

    this.props.updateUsername(user);
    console.log("user property ==" + this.props.updateUsername);
    // setup some variables out of our current user object
    const token = user.signInUserSession.idToken.jwtToken;
    const user_givenname = user.userName;
    const user_email = user.attributes.email;

    // set the variables into the classes state.
    this.setState({ token: token });
    this.setState({ user: user });
    this.setState({ user_givenname: user_givenname });
    console.log("user_givenname ==" + user_givenname);
    this.setState({ user_email: user_email });
    console.log("user_email ==" + user_email);
  };

  // ====================================================
  // componentDidMount(): react core function
  // is invoked immediately after a component is mounted
  // https://reactjs.orgnpm/docs/react-component.html
  componentDidMount() {
    // Setup a hub listenr on the auth events
    // https://aws-amplify.github.io/docs/js/hub
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          this.props.onStateChange("signedIn", {});
          this.getuserinfo();
          break;
        case "verifyContact":
          this.props.onStateChange("signedIn", {});
          this.getuserinfo();
          break;
        case "signOut":
          this.props.onStateChange("signIn", {});
          this.setState({ user: null });
          break;
      }
    });
  }

  //===================================================
  // signIn() : used to sign in user using AD Login
  //custom sign in function; has been bound in constructor(props) as well
  signIn() {
    console.error("Auth State = ", this.props.authState);
    Auth.federatedSignIn()
      .then(() => {
        this.props.onStateChange("signedIn", {});
        //this.setState({ authState: "signedIn" });
        //this.setState({ user: this.getuserinfo() });
        console.log("After sign In ...Auth State = ", this.props.authState);
        return this.getuserinfo();
      })
      .catch((err) => {
        console.error(err);
        console.error(Auth.payload);
      });
  }

  // ====================================================
  // signOut() : used to sign out user
  // custom sign out function; has been bound in constructor(props) as well
  async signOut() {
    await Auth.signOut()
      .then(() => {
        this.props.onStateChange("signIn", {});
        //this.setState({ authState: "signIn" });
        this.setState({ user: null });
      })
      .catch((err) => {
        logger.error(err);
      });
  }

  // ====================================================
  // render(): mandatory react render function
  render() {
    // vars for fun - should be buried in app
    //const { authState } = this.state;
    const { token } = this.state;
    console.log("token = " + token);
    const { user_givenname } = this.state;
    const { user_email } = this.state;

    console.log("authState = " + this.props.authState);
    // main return routine
    return (
      <div className="login-parent">
        {
          // if authState is null display loading message.
          this.props.authState === null && <div>loading...</div>
        }
        {
          // if authState is set to signIn then show the login page with the single button for O365 javascript redirect
          this.props.authState === "signIn" && (
            <div className="login-parent">
              <div className="login-container">
                <div className="btn-div">
                  <span>
                    <p>This will redirect to FBAU single sign on</p>
                  </span>
                  <button className="signIn-button" onClick={this.signIn}>
                    Login to Just Book It
                  </button>
                </div>
              </div>
            </div>
          )
        }
        {
          // if authState is signedIn then we've got a logged in user - lets start our app up!
          // or rather lets just show a sign out button for now.. sigh.
          /*authState === "signedIn" ? (
            <ReservationApp
              authState={this.state.authState}
              onStateChange={this.props.onStateChange}
            />
          ) : null*/
        }
      </div>
    );
  }
}

// Export the App.  No Auth Wrapper required for AzureAD as this is undertaken in the OAuthLogin.js
export default AdLogin;
