import React from "react";
import DefaultImage from "../../images/deskReservationHome.png";

function DefaultPage() {
  return (
    <div className="container">
      <img src={DefaultImage} className="default-map" />
    </div>
  );
}

export default DefaultPage;
