import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./CancelReservation.css";

export class ErrorMessageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="WarningMessage">
            <p>{this.props.errormessage}</p>
            <p>Please click Manage Reservation to manage your bookings</p>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}
