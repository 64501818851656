import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import ImageMapper from "react-img-mapper";
import { ReserveDeskModal } from "../pages/ReserveDeskModal";
import { ErrorMessageModal } from "../pages/ErrorMessageModal";
import homeImg from "../../images/deskReservationNav.png";

import Tooltip from "react-simple-tooltip";

export default class FloorMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MasterData: props.MasterData,
      hoveredArea: null,
      showReserveDeskModal: false,
      showErrorModal: false,
      userSearchData: props.SearchData,
      selectedDesk: "",
      deskStatus: "",
      Reservations: [],
      errorMessage: "",
      resultFromModal: "",
    };
    console.log(this.state.MasterData);
    this.getPreviousReservation();
  }

  enterArea(area) {
    this.setState({ hoveredArea: area });
    this.setState({ selectedDesk: area.name });
  }

  leaveArea(area) {
    this.setState({ hoveredArea: null });
  }

  getTipPosition(area) {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  }

  setDeskState(area) {
    this.setState({
      selectedDesk: area.name,
      deskStatus: area.alt,
    });
  }

  updateFloorMap = (reservationSaved) => {
    this.setState({ resultFromModal: reservationSaved });

    if (this.state.resultFromModal === "success") {
      this.props.LoadFloorMap("YES");
    }

    this.getPreviousReservation();
  };

  async getPreviousReservation() {
    //validate if user already have reservation for selected date
    let currentUserInfo = await Auth.currentUserInfo();
    console.log("currentUserInfo " + currentUserInfo.attributes);
    let currentUser = currentUserInfo.attributes.email;
    let startDateString = this.props.SearchData.reservStartDate;
    let endDateString = this.props.SearchData.reservEndDate;

    let queryParam = {
      queryStringParameters: {
        userName: currentUser,
        reservedFromDate: startDateString,
        reservedToDate: endDateString,
      },
    };

    let previousReservation = [];
    let bookedDesks = "";
    API.get("userBookingsAPI", "/userBookings", queryParam)
      .then((resp) => {
        previousReservation = resp.map((reservation) => {
          if (reservation.userName === currentUser) {
            console.log("reservation.userName = " + reservation.userName);
            let obj = {};
            obj.id = reservation.id;
            obj.siteId = reservation.siteId;
            obj.deskId = reservation.deskId;
            obj.reservedFromDate = reservation.reservedFromDate;
            obj.reservedToDate = reservation.reservedToDate;
            console.log(obj);
            return obj;
          }
        });

        if (previousReservation.length > 0) {
          this.setState({ Reservations: previousReservation });

          this.setState({
            errorMessage:
              "You have desk  : " +
              this.state.Reservations[0].deskId +
              "  booked for selected Date Range  ",
          });
        }
        return;
      })
      .catch((error) => {
        console.log("error" + error);
      });
  }

  openReservModal = (event) => {
    if (!this.state.deskStatus.includes("Blocked")) {
      if (this.props.SearchData.blockFlag === "Y") {
        console.log(this.state.selectedDesk);
        let deskAPIParams = {
          queryStringParameters: {
            deskId: this.state.selectedDesk,
          },
        };
        //call desk service
        API.put("deskAPI", "/desk", deskAPIParams)
          .then((resp) => {
            console.log(resp);
          })
          .catch((error) => {
            console.log("error" + error);
          });
      } else {
        if (!this.state.deskStatus.includes("Booked")) {
          if (this.state.errorMessage) {
            this.setState({
              showErrorModal: true,
            });
          } else {
            this.setState({
              showReserveDeskModal: true,
            });
          }
        }
      }
    }
  };

  render() {
    const reserveDeskModalClose = () =>
      this.setState({ showReserveDeskModal: false });

    const errorModalClose = () => this.setState({ showErrorModal: false });
    var floorMapName = String(this.state.MasterData["floorMapPath"]);

    var imagePath = homeImg;
    if (floorMapName !== "undefined") {
      imagePath = require("../../images/" + floorMapName).default;
    }
    return (
      <>
        <div className="container">
          <ImageMapper
            src={imagePath}
            map={this.state.MasterData}
            width={this.state.MasterData.mapwidth}
            height={this.state.MasterData.mapHeight}
            className="deskReaervationMap"
            onMouseEnter={(area) => {
              this.enterArea(area);
              this.setDeskState(area);
            }}
            onMouseLeave={(area) => this.leaveArea(area)}
            onClick={this.openReservModal}
          />
          {this.state.hoveredArea && (
            <Tooltip
              content={this.state.hoveredArea.alt}
              style={{
                position: "absolute",
                padding: "10px",
                radius: "5px",
                zIndex: 200,
                ...this.getTipPosition(this.state.hoveredArea),
              }}
            ></Tooltip>
          )}

          {this.state.showReserveDeskModal ? (
            <ReserveDeskModal
              selecteddesk={this.state.selectedDesk}
              selectedreservation={this.state.userSearchData}
              refreshfloormap={this.updateFloorMap}
              show={this.state.showReserveDeskModal}
              onHide={reserveDeskModalClose}
            />
          ) : null}

          {this.state.showErrorModal ? (
            <ErrorMessageModal
              errormessage={this.state.errorMessage}
              show={this.state.showErrorModal}
              onHide={errorModalClose}
            />
          ) : null}
        </div>
      </>
    );
  }
}
