/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "cancelReservationAPI",
            "endpoint": "https://csn2px232m.execute-api.ap-southeast-2.amazonaws.com/master",
            "region": "ap-southeast-2"
        },
        {
            "name": "deskAPI",
            "endpoint": "https://ik71c91raa.execute-api.ap-southeast-2.amazonaws.com/master",
            "region": "ap-southeast-2"
        },
        {
            "name": "emailNotificationAPI",
            "endpoint": "https://wqm0wtwctb.execute-api.ap-southeast-2.amazonaws.com/master",
            "region": "ap-southeast-2"
        },
        {
            "name": "reservationAPI",
            "endpoint": "https://urmpqxi8c7.execute-api.ap-southeast-2.amazonaws.com/master",
            "region": "ap-southeast-2"
        },
        {
            "name": "siteAPI",
            "endpoint": "https://0q47dtljg8.execute-api.ap-southeast-2.amazonaws.com/master",
            "region": "ap-southeast-2"
        },
        {
            "name": "userBookingsAPI",
            "endpoint": "https://39xje8t1oi.execute-api.ap-southeast-2.amazonaws.com/master",
            "region": "ap-southeast-2"
        },
        {
            "name": "validationAPI",
            "endpoint": "https://91z5o6uut6.execute-api.ap-southeast-2.amazonaws.com/master",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:91ad4ad7-c23f-4521-83d5-5b724a9e3512",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_8hbQAS3Yl",
    "aws_user_pools_web_client_id": "1l2c4f0dmd2poic300okevbfnh",
    "oauth": {
        "domain": "justbookit-fbau-master.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://justbookit-fbau.fujifilm.com/",
        "redirectSignOut": "https://justbookit-fbau.fujifilm.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "JbiDeskTable-master",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "JbiReservationTable-master",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "JbiSiteTable-master",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
