import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import { Button, Table } from "react-bootstrap";
import "./CancelReservation.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class CancelReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      checkedItems: [],
      Reservations: [],
      successMessage: "",
      //Spinner prop
      showLoader: false,
    };
  }

  getUserReservations() {
    let currentUser = Auth.user.attributes.email;
    console.log("currentUser   - " + currentUser);

    //load all the reservations for logged in user
    let cancelAPIParam = {
      queryStringParameters: {
        userName: currentUser,
      },
    };

    this.setState({
      showLoader: true,
    });
    this.closeLoaderIn5Seconds();
    let reservationArray = [];
    API.get("cancelReservationAPI", "/cancelReservation", cancelAPIParam)
      .then((cancelResp) => {
        reservationArray = cancelResp.map((reservation) => {
          let obj = {};
          obj.id = reservation.id;
          obj.siteId = reservation.siteId;
          obj.deskId = reservation.deskId;
          obj.reservedFromDate = reservation.reservedFromDate;
          obj.reservedToDate = reservation.reservedToDate;
          obj.isSelected = false;
          return obj;
        });
        this.setState({ Reservations: reservationArray });
      })
      .catch((error) => {
        console.log("error" + error);
      });
  }

  componentDidMount() {
    this.getUserReservations();
  }

  handleChange = (event) => {
    let items = this.state.Reservations;
    let checkedItems = [];
    items.map((element) => {
      if (element.id === event.target.name) {
        element.isSelected = event.target.checked;
        checkedItems[element.id] = event.target.checked;
      }
    });

    this.setState({
      reservations: items,
      checkedItems: checkedItems,
    });
  };

  cancelReservation(id) {
    console.log("ID = " + id);
    let deleteParams = {
      queryStringParameters: {
        id: id,
      },
    };
    let path = "/cancelReservation";
    if (window.confirm("Are you sure you want to cancel the reservation?")) {
      API.del("cancelReservationAPI", path, deleteParams)
        .then((cancelResp) => {
          console.log(cancelResp);
          this.getUserReservations();
          this.setState({ successMessage: cancelResp.success });
        })
        .catch((error) => {
          console.log("error ----" + error);
          this.setState({ successMessage: error.error });
        });
    }
  }

  closeLoaderIn5Seconds = () => {
    setTimeout(() => {
      this.setState({
        showLoader: false,
      });
    }, 3000);
  };

  render() {
    const reservations = this.state.Reservations;

    return (
      <div className="container">
        <form onSubmit={this.handleSubmit}>
          <div>
            <p className="Message">{this.state.successMessage}</p>
          </div>
          <div className="loaderDiv">
            {this.state.showLoader && (
              <Loader type="Puff" color="#01916D" height={100} width={100} />
            )}
          </div>
          <div>
            {this.state.showLoader === false && (
              <Table
                className="mt-4"
                stripped="true"
                bordered="true"
                hover="true"
                size="sm"
              >
                <thead>
                  <th>ID </th>
                  <th>Site </th>
                  <th>Desk </th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th></th>
                </thead>
                <tbody>
                  {reservations.map((reservation) => (
                    <tr key={reservation.id}>
                      <td>{reservation.id}</td>
                      <td>{reservation.siteId}</td>
                      <td>{reservation.deskId}</td>
                      <td>{reservation.reservedFromDate.split('-').reverse().join('/')}</td>
                      <td>{reservation.reservedToDate.split('-').reverse().join('/')}</td>
                      <td>
                        <Button
                          //className="mr-2"
                          className="button-style"
                          onClick={() => this.cancelReservation(reservation.id)}
                          variant="danger"
                        >
                          Cancel Booking
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default CancelReservation;
