import React from "react";
import deskImg from "../../images/deskReservationNav.png";

function Footer() {
  return (
    <div className="defaultHero">
      <img src={deskImg} className="desk-logo" alt="Just Book It!" />
    </div>
  );
}

export default Footer;
